import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Layout from "../components/Layout";
import LogoAnimated from "../components/LogoAnimated";
import Seo from "../components/Seo";
import SideImageWithText from "../components/SideImageWithText";
import SupportedBanks from "../components/SupportedBanks";

const useStyles = makeStyles((theme) => ({
  featureLogoSection: {
    marginTop: 100,
    marginBottom: 75,
  },
  featureLogo: {
    width: 100,
    [theme.breakpoints.up("sm")]: {
      width: 150,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  subtitle: {
    marginBottom: 100,
  },
  keyValues: {
    marginBottom: 100,
  },
}));

const IndexPage = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Seo title="Cash Flow Analytics" />
      <Grid
        alignItems="center"
        className={classes.featureLogoSection}
        container
        justify="center"
        direction="row"
      >
        <Grid className={classes.featureLogo} item>
          <LogoAnimated />
        </Grid>
        <Grid item>
          <Typography color="primary" variant="h1">
            Sumit
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="h2"
        color="secondary"
        className={classes.subtitle}
        align="center"
      >
        360-degree view of your cash flow
      </Typography>

      <Grid container>
        <SideImageWithText
          image={
            <StaticImage
              src="../assets/screenshots/report_category.png"
              layout="fluid"
              maxWidth={1500}
            />
          }
          index={0}
        >
          <Typography variant="h3" color="secondary">
            Make your bad habits history.
          </Typography>
          <Typography variant="h5">
            Identify long term trends, and see your decisions pay off over time.
          </Typography>
        </SideImageWithText>

        <SideImageWithText
          image={
            <StaticImage
              src="../assets/screenshots/report_income.png"
              layout="fluid"
              maxWidth={1500}
            />
          }
          index={1}
        >
          <Typography variant="h3" color="secondary">
            Data, at a glance.
          </Typography>
          <Typography variant="h5">
            Gain a better understanding of your income and expenses using a
            variety of interactive visualisations.
          </Typography>
        </SideImageWithText>

        <SideImageWithText
          image={
            <StaticImage
              src="../assets/screenshots/accounts.png"
              layout="fluid"
              maxWidth={1500}
            />
          }
          index={2}
        >
          <Typography variant="h3" color="secondary">
            Be agile with your accounts.
          </Typography>
          <Typography variant="h5" gutterBottom>
            Sumit aggregates data from all of your accounts into one place,
            giving you a complete view of your cash flow position.
          </Typography>
          <Typography variant="h5">
            It works with a number of Australian banks, giving you the freedom
            to move your funds between accounts while retaining a complete
            financial history.
          </Typography>
        </SideImageWithText>

        <SideImageWithText
          image={
            <StaticImage
              src="../assets/screenshots/import.png"
              layout="fluid"
              maxWidth={1500}
            />
          }
          index={3}
        >
          <Typography variant="h3" color="secondary">
            Secure, for sure.
          </Typography>
          <Typography variant="h5" gutterBottom>
            You can choose what data is imported into Sumit, and we will never
            ask for your bank's login details.
          </Typography>
          <Typography variant="h5">
            Your data is secured using modern industry standards, from the cloud
            to your device.
          </Typography>
        </SideImageWithText>
      </Grid>

      <SupportedBanks />
    </Layout>
  );
};

export default IndexPage;
