import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const useStyles = makeStyles(() => ({
  icon: {
    verticalAlign: "sub",
  },
}));

const SUPPORTED_BANKS = [
  {
    name: "ANZ",
    image: (
      <StaticImage height={50} src="../assets/bankLogos/anz.png" alt="ANZ" />
    ),
  },
  {
    name: "UBank",
    image: (
      <StaticImage
        height={50}
        src="../assets/bankLogos/ubank.png"
        alt="UBank"
      />
    ),
  },
  {
    name: "Commbank",
    image: (
      <StaticImage
        height={50}
        src="../assets/bankLogos/commonwealthBank.png"
        alt="Commbank"
      />
    ),
  },
  {
    name: "NAB",
    image: (
      <StaticImage height={50} src="../assets/bankLogos/nab.png" alt="NAB" />
    ),
  },
  {
    name: "American Express",
    image: (
      <StaticImage
        height={50}
        src="../assets/bankLogos/americanExpress.png"
        alt="American Express"
      />
    ),
  },
  {
    name: "Westpac",
    image: (
      <StaticImage
        height={50}
        src="../assets/bankLogos/westpac.png"
        alt="Westpac"
      />
    ),
  },
  {
    name: "Up",
    image: (
      <StaticImage height={50} src="../assets/bankLogos/up.png" alt="Up" />
    ),
  },
];

export default function SupportedBanks() {
  const classes = useStyles();

  return (
    <Grid container spacing={4} alignItems="center" justify="center">
      {SUPPORTED_BANKS.map((b) => (
        <Grid item>
          <Tooltip title={b.name} placement="top">
            <div>{b.image}</div>
          </Tooltip>
        </Grid>
      ))}
      <Grid item>
        <Typography variant="h6">
          <AddIcon className={classes.icon} /> More to come
        </Typography>
      </Grid>
    </Grid>
  );
}
