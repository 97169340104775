import { Grid, makeStyles, Paper } from "@material-ui/core";
import clsx from "clsx";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  screenshotContainer: {
    overflow: "hidden",
  },
  root: {
    marginBottom: theme.spacing(16),
  },
  smallScreenFirstItem: {
    [theme.breakpoints.down("xs")]: {
      order: 1,
    },
  },
  smallScreenSecondItem: {
    [theme.breakpoints.down("xs")]: {
      order: 2,
    },
  },
  textRight: {
    textAlign: "right",
  },
}));

export default function SideImageWithText({
  children,
  image,
  index,
}: {
  children: ReactNode;
  image: ReactNode;
  index: number;
}) {
  const classes = useStyles();
  const imageFirstLargeScreen = index % 2 === 0;

  const imageEl = (
    <Grid
      item
      xs={12}
      sm={6}
      md={7}
      className={clsx({
        [classes.smallScreenFirstItem]: !imageFirstLargeScreen,
      })}
    >
      <Paper elevation={24} className={classes.screenshotContainer}>
        {image}
      </Paper>
    </Grid>
  );

  const textEl = (
    <Grid
      item
      xs={12}
      sm={6}
      md={5}
      className={clsx({
        [classes.smallScreenSecondItem]: !imageFirstLargeScreen,
        [classes.textRight]: !imageFirstLargeScreen,
      })}
    >
      {children}
    </Grid>
  );

  return (
    <Grid
      item
      container
      className={classes.root}
      spacing={4}
      alignItems="center"
    >
      {imageFirstLargeScreen ? (
        <>
          {imageEl}
          {textEl}
        </>
      ) : (
        <>
          {textEl}
          {imageEl}
        </>
      )}
    </Grid>
  );
}
